<template>
  <ConfirmDialog
    :cancel-button-type="cancelButtonType"
    :confirm-button-type="confirmButtonType"
    :message="message"
    :show-dialog="showDialog"
    @close="handleClose"
  />
</template>

<script>
import { ButtonTypes } from '@@/components/Common/Button.vue';

export default {
  name: 'RemoveCustomLocationDialog',

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close'],

  computed: {
    cancelButtonType() {
      return ButtonTypes.secondary;
    },

    confirmButtonType() {
      return ButtonTypes.danger;
    },

    message() {
      return 'Deleting this custom location will also remove the estimated snowfall history beyond the last 5 days.';
    },
  },

  methods: {
    handleClose(canContinue) {
      this.$emit('close', canContinue);
    },
  },
};
</script>
